
import Vue from "vue"
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { getCountries, getCountryCallingCode, parsePhoneNumber, getExampleNumber } from 'libphonenumber-js/mobile'
import examples from 'libphonenumber-js/examples.mobile.json'
import type { CountryCode } from 'libphonenumber-js/mobile'

const localizedCountries = {
  // This has been generated with the last column of https://fr.wikipedia.org/wiki/ISO_3166-1#Table_de_codage
  // We decided to keep only the first occurence and removed everything after " ; "
  "AF": "د افغانستان اسلامي دولتدولت اسلامی افغانستان",
  "ZA": "Republic of South Africa",
  "AX": "Landskapet Åland",
  "AL": "Shqipëri",
  "DZ": "الجمهورية الجزائرية الديمقراطية الشعبية",
  "DE": "Bundesrepublik Deutschland",
  "AD": "Principat d’Andorra",
  "AO": "República de Angola",
  "AI": "Anguilla",
  "AQ": "Traité sur l’Antarctique",
  "AG": "Antigua and Barbadua",
  "SA": "المملكة العربية السعودية",
  "AR": "Argentina",
  "AM": "Հայաստան",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Österreich",
  "AZ": "Azərbaycan Respublikası",
  "BS": "Commonwealth of the Bahamas",
  "BH": "مملكة البحرين",
  "BD": "গণপ্রজাতন্ত্রী বাংলাদেশ",
  "BB": "Barbados",
  "BY": "Беларусь",
  "BE": "Belgique",
  "BZ": "Belize",
  "BJ": "Bénin",
  "BM": "Bermuda",
  "BT": "འབྲུག་ཡུལ",
  "BO": "Bolivia",
  "BQ": "Bonaire, Sint-Eustatius, en Saba",
  "BA": "Republika Bosna i Hercegovina",
  "BW": "Republic of Botswana",
  "BV": "Bouvetøya",
  "BR": "República Federativa do Brasil",
  "BN": "بروني دارالسلام",
  "BG": "Република България",
  "BF": "Burkina Faso",
  "BI": "République du Burundi",
  "KY": "Cayman Islands",
  "KH": "ព្រះរាជាណាចក្រកម្ពុជា",
  "CM": "Cameroun",
  "CA": "Canada",
  "CV": "Cabo Verde",
  "CF": "République centrafricaine",
  "CL": "Chile",
  "CN": "中华人民共和国",
  "CX": "Christmas Islands",
  "CY": "Κύπρος",
  "CC": "Territory of Cocos Island",
  "CO": "Colombia",
  "KM": "Union des Comores",
  "CG": "République du Congo",
  "CD": "République démocratique du Congo",
  "CK": "Cook Islands",
  "KR": "대한민국",
  "KP": "조선민주주의인민공화국",
  "CR": "República de Costa Rica",
  "CI": "Côte d’Ivoire",
  "HR": "Republika Hrvatska",
  "CU": "República de Cuba",
  "CW": "Curaçao",
  "DK": "Kongeriget Danmark",
  "DJ": "République de Djibouti",
  "DO": "República Dominicana",
  "DM": "Commonwealth of Dominica",
  "EG": "جمهوريّة مصر العربيّة",
  "SV": "República de El Salvador",
  "AE": "دولة الإمارات العربيّة المتّحدة",
  "EC": "República del Ecuador",
  "ER": "ሃገረ ኤርትራ",
  "ES": "España",
  "EE": "Eesti",
  "US": "United States of America",
  "ET": "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
  "FK": "Falkland Islands",
  "FO": "Føroyar",
  "FJ": "Republic of Fiji",
  "FI": "Suomen Tasavalta",
  "FR": "République française",
  "GA": "République gabonaise",
  "GM": "Republic of Gambia",
  "GE": "საქართველო",
  "GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
  "GH": "Republic of Ghana",
  "GI": "Gibraltar ",
  "GR": "Ελλάδα",
  "GD": "Commonwealth of Grenada",
  "GL": "Kalaallit Nunaat",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "República de Guatemala",
  "GG": "Guernsey",
  "GN": "République de Guinée",
  "GW": "República da Guiné-Bissau",
  "GQ": "República de Guiena ecuatorial",
  "GY": "Co-Operative Republic of Guyana",
  "GF": "Guyane",
  "HT": "Repiblik d'Ayiti",
  "HM": "Heard Island and McDonald Islands",
  "HN": "República de Honduras",
  "HK": "Hong Kong",
  "HU": "Magyar",
  "IM": "Isle of Man",
  "UM": "United States Minor Outlying Islands",
  "VG": "British Virgin Islands",
  "VI": "US Virgin Islands",
  "IN": "Republic of India",
  "ID": "Republik Indonesia",
  "IR": "جمهوری اسلامی ايران",
  "IQ": "العراق",
  "IE": "Éire",
  "IS": "Ísland",
  "IL": "מְדִינַת יִשְׂרָאֵל",
  "IT": "Italia",
  "JM": "Jamaïca",
  "JP": "日本国)",
  "JE": "Bailiwick of Jersey",
  "JO": "المملكة الأردنّيّة الهاشميّة",
  "KZ": "Қазақстан Республикасы",
  "KE": "Jamhuri ya Kenya",
  "KG": "Кыргыз Республикасы",
  "KI": "Kiribati",
  "KW": "دولة الكويت",
  "LA": "ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ",
  "LS": "Muso oa Lesotho",
  "LV": "Latvijas",
  "LB": "République du Liban",
  "LR": "Republic of Liberia",
  "LY": "دولة ليبيا",
  "LI": "Fürstentum Liechtenstein",
  "LT": "Lietuvos Respublika",
  "LU": "Groussherzogtum Lëtzebuerg",
  "MO": "Região Administrativa Especial de Macau da República Popular da China",
  "MK": "Република Македонија",
  "MG": "République de Madagascar",
  "MY": "Malaysia",
  "MW": "Republic of Malawi",
  "MV": "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
  "ML": "République du Mali",
  "MT": "Repubblika ta' Malta",
  "MP": "Commonwealth of the Northern Mariana Islands",
  "MA": "المملكة المغربية",
  "MH": "Aolepān Aorōkin M̧ajeļ",
  "MQ": "Martinique",
  "MU": "Mauritius",
  "MR": "République islamique de Mauritanie",
  "YT": "Mayotte",
  "MX": "Estados Unidos Mexicanos",
  "FM": "Federated States of Micronesia",
  "MD": "Republica Moldova",
  "MC": "Monaco ",
  "MN": "Монгол Улс",
  "ME": "Црна Гора",
  "MS": "Montserrat",
  "MZ": "República de Moçambique",
  "MM": "Union of Myanmar",
  "NA": "Namibia",
  "NR": "Ripublik Naoero",
  "NP": "Népal",
  "NI": "Republica de Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "NO": "Kongeriket Norge",
  "NC": "Nouvelle-Calédonie",
  "NZ": "New Zealand",
  "IO": "British Indian Ocean Territory",
  "OM": "سلطنة عُمان",
  "UG": "Republic of Uganda",
  "UZ": "O’zbekiston Respublikasi",
  "PK": "اسلامی جمہوریت پاکستان",
  "PW": "Republic of Palau",
  "PS": "دولة فلسطين",
  "PA": "República de Panamá",
  "PG": "l'État indépendant de Papouasie-Nouvelle-Guinée",
  "PY": "República del Paraguay",
  "NL": "Nederland",
  "PE": "Perú",
  "PH": "Republika ng Pilipinas",
  "PN": "Pitcairn",
  "PL": "Polska",
  "PF": "Polynésie française",
  "PR": "Estado Libre Asociado de Puerto Rico",
  "PT": "Portugal",
  "QA": "دولة قطر",
  "RE": "La Réunion",
  "RO": "România",
  "GB": "United Kingdom",
  "RU": "Российская Федерация",
  "RW": "Repubulika y'u Rwanda",
  "EH": "Western Sahara",
  "BL": "Saint-Barthélemy",
  "KN": "Saint Kitts and Nevis",
  "SM": "San Marino",
  "MF": "Saint-Martin",
  "SX": "Sint Maarten",
  "PM": "Saint-Pierre-et-Miquelon",
  "VA": "Stato della Città del Vaticano",
  "VC": "Saint Vincent and the Grenadines",
  "SH": "Saint Helena",
  "LC": "Commonwealth of Saint Lucia",
  "SB": "Solomons Islands",
  "WS": "Malo Sa'oloto Tuto'atasi o Samoa",
  "AS": "American Samoa",
  "ST": "República Democrática de São Tomé e Príncipe",
  "SN": "République du Sénégal",
  "RS": "Република Србија",
  "SC": "Repiblik Sesel",
  "SL": "Sierra Leone",
  "SG": "Republic of Singapore",
  "SK": "Slovenská republika",
  "SI": "Republika Slovenija",
  "SO": "la République fédérale de Somalie",
  "SD": "جمهورية السودان",
  "SS": "Republic of South Sudan",
  "LK": "Prajatantrika Samajavadi Janarajaya",
  "SE": "Sverige",
  "CH": "Confœderatio Helvetica",
  "SR": "Republiek Suriname",
  "SJ": "Svalbard og Jan Mayen",
  "SZ": "Umbuso we Swatini",
  "SY": "الجمهوريّة العربيّة السّوريّة",
  "TJ": "Ҷумҳурии Тоҷикистон",
  "TW": "台灣",
  "TZ": "United Republic of Tanzania",
  "TD": "جمهورية تشاد",
  "CZ": "Česká republika",
  "TF": "Terres australes et antarctiques françaises",
  "TH": "ราชอาณาจักรไทย",
  "TL": "Repúblika Demokrátika Timor Lorosa'e",
  "TG": "République togolaise",
  "TK": "Tokelau",
  "TO": "Pule'anga Fakatu'i 'o Tonga",
  "TT": "Republic of Trinidad and Tobago",
  "TN": "الجمهورية التونسية",
  "TM": "Türkmenistan Respublikasy",
  "TC": "Turks-and-Caicos",
  "TR": "Türkiye Cumhuriyeti",
  "TV": "Tuvalu",
  "UA": "Украïна",
  "UY": "República Oriental del Uruguay",
  "VU": "Ripablik blong Vanuatu",
  "VE": "República Bolivariana de Venezuela",
  "VN": "Cộng Hoà Xã Hội Chủ Nghĩa Việt Nam",
  "WF": "Wallis-et-Futuna",
  "YE": "ﺍﻟﺠﻤﻬﻮﺭﯾّﺔ اليمنية",
  "ZM": "Republic of Zambia",
  "ZW": "Republic of Zimbabwe",
  // Not in wikipedia article
  "XK": "Kosovë",
  "TA": "Tristan da Cunha",
  "AC": "Ascension Island"
}

export default Vue.extend({
  data: () => ({
    value: '',
    country: 'FR' as CountryCode, // selected country to use on libphonenumber-js
    defaultCountry: 'FR' as CountryCode, // used as a fallback value if user delete empty value from autcomplete <input>
    countries: getCountries().map(eachCountry => { // we generate data from available country codes from libphonenumber-js
      return {
        value: eachCountry,
        text: getUnicodeFlagIcon(eachCountry) + ' ' + localizedCountries[eachCountry] + ' - ' + eachCountry + ' (+' + getCountryCallingCode(eachCountry) + ')',
        prefix: '+' + getCountryCallingCode(eachCountry)
      }
    }),
  }),
  props: {
    optional: Boolean,
    phone: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default() { 
        return []
      }
    }
  },
  model: { 
    event: 'emitPhone'
  },
  mounted() {
    this.value = this.phone
  },
  methods: {
    emitError(errorDetected: boolean) {
      this.$emit('emitError', errorDetected)
    },
    onClearClicked () {
      this.$emit('clearMobilePhoneNumber')
    },
    format() {
      try {
        let number = parsePhoneNumber(this.value, this.country).number
        this.$emit('emitPhone', number)
      } catch (error) {
        // if phone is optionnal, we still need to return the emtpy string value
        if(this.optional) this.$emit('emitPhone', this.value)
        return true
      }
    },
    setPrefix(prefix: string) { // set default country & country by prefix, it is imperfect as multiple countries can share the same prefix
      let firstMatch = this.countries.find(eachCountry => eachCountry.prefix == prefix)
      if (firstMatch) {
        this.defaultCountry = firstMatch.value
        this.country = firstMatch.value
      }
    },
    getValue() {
      try {
        const phoneNumber = parsePhoneNumber(this.value, this.country)
        if (phoneNumber.isValid()) {
          return phoneNumber.number
        }
        return false
      } catch (error) {
        return false
      }
    }
  },
  computed: {
    example: function() {
      let example = ''
      const examplePhoneNumber = getExampleNumber(this.country, examples)
      if (examplePhoneNumber) {
        example = examplePhoneNumber.formatNational()
      }
      return example
    },
    selectedFlag() {
      return getUnicodeFlagIcon(this.country)
    },
    validValueRule() {
      let errorText = this.$vuetify.lang.t('$vuetify.invalid_mobile_phone_number')
      if (this.optional && !this.value) {
        this.emitError(true)
        return true
      }
      try {
        const phoneNumber = parsePhoneNumber(this.value, this.country)
        if ((!phoneNumber.number || phoneNumber.isValid())) {
          this.emitError(false)
          return true
        }
        this.emitError(true)
        return errorText
      } catch (error) {
        this.emitError(true)
        return errorText
      }
    },
    requiredRule() {
      if (this.optional) {
        return true
      }
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    }
  },
  watch: {
    country: function(value) {
      // prevent setting country to null with backspace on autocomplete input
      if (value === null) {
        this.country = this.defaultCountry
      }
      // format value when country has changed
      this.format()
    },
    value: function() {
      if(this.value === null || this.value.length === 0) {
        this.onClearClicked()
      }    
    }
  }
})
